import PropTypes from "prop-types";
import SnackBarHelpers from "helpers/snack-bar-helpers";

const SnackBar = ({
  classNames = "",
  copyPrimary = "",
  copySecondary = "",
  ctaFunc = () => void 0,
  ctaText = "",
  dismissSnackBar,
  hasCloseBtn = false,
  icon = "",
}) => {
  const isThereCta = ctaText && ctaFunc && typeof ctaFunc === "function";
  const classSuffix = classNames ? classNames : "";
  const classes = `snack-bar ${classSuffix}`;

  return (
    <div className={classes}>
      {icon && <div className="snack-bar__icon">{icon}</div>}
      <div className="snack-bar__copy-container">
        <p className="snack-bar__copy--primary">{copyPrimary}</p>
        {copySecondary && <p className="snack-bar__copy--secondary">{copySecondary}</p>}
      </div>
      {isThereCta && (
        <button className="snack-bar__cta" onClick={ctaFunc}>
          {ctaText}
        </button>
      )}
      {hasCloseBtn && (
        <button className="snack-bar__close" onClick={dismissSnackBar}>
          &times;
        </button>
      )}
    </div>
  );
};

SnackBar.propTypes = {
  ...SnackBarHelpers.propTypes,
  dismissSnackBar: PropTypes.func.isRequired,
};

export default SnackBar;

export const { propTypes } = SnackBar;
