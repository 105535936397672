import PropTypes from "prop-types";

const SPECIAL_INSTRUCTIONS_LIMIT = 57;
const propTypes = {
  isOpen: PropTypes.bool,
  setSpecialInstructions: PropTypes.func.isRequired,
  specialInstructions: PropTypes.string,
};

const AdditionalInstructionsTextArea = ({ isOpen, setSpecialInstructions, specialInstructions }) => {
  return (
    <div className={`additional-instructions ${isOpen ? "open" : ""}`} data-test-id="additional-instructions">
      <span className={`character-count ${isOpen ? "open" : ""}`}>
        {SPECIAL_INSTRUCTIONS_LIMIT - specialInstructions?.length}
      </span>
      <textarea
        className={`universal-xsmall text-area${isOpen ? " visible" : ""}`}
        placeholder="Any details to note? (i.e., access code, pick up from doorman, pick up from package room, etc.)"
        value={specialInstructions}
        onChange={e => setSpecialInstructions(e.target.value.slice(0, SPECIAL_INSTRUCTIONS_LIMIT))}></textarea>
    </div>
  );
};

AdditionalInstructionsTextArea.propTypes = propTypes;
export default AdditionalInstructionsTextArea;
