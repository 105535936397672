import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { returnDayShortened, returnDateShortened, formatTruncatedDate } from "helpers/date-helpers";

const propTypes = {
  isReworkedScheduler: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  pickupDate: PropTypes.string.isRequired,
};
const HomePickupDateCard = ({ isReworkedScheduler, isSelected, onClick, pickupDate }) => {
  const className = classNames("date-card", {
    "selected-button": isSelected,
  });

  return (
    <button className={className} onClick={onClick}>
      <div className="text-container">
        <span className="day universal-small--semibold">{`${returnDayShortened(pickupDate)}${
          isReworkedScheduler ? "." : ""
        }`}</span>
        <span className="date universal-small">
          {isReworkedScheduler ? formatTruncatedDate(pickupDate, true) : returnDateShortened(pickupDate)}
        </span>
      </div>
    </button>
  );
};

HomePickupDateCard.propTypes = propTypes;

export default HomePickupDateCard;
