import React from "react";
import PropTypes from "prop-types";
import { formatDateMonthDay, formatTimeCondensedHours } from "helpers/date-helpers";
import HomePickupDateCard from "./home-pickup-date-card";

const propTypes = {
  handleOnSelectDate: PropTypes.func,
  isReworkedScheduler: PropTypes.bool,
  multipleDatesFeatureFlag: PropTypes.bool,
  pickupDetails: PropTypes.object.isRequired,
};
const HomePickupDatePicker = ({ handleOnSelectDate, isReworkedScheduler, multipleDatesFeatureFlag, pickupDetails }) => {
  const { preferredPickupDate, pickupWindowStart, pickupWindowEnd, pickupDateOptions = [] } = pickupDetails;

  const handleDateClick = (e, date) => {
    e.preventDefault();
    handleOnSelectDate(date);
  };

  const isMultiDatePicker = multipleDatesFeatureFlag && (isReworkedScheduler || pickupDateOptions.length > 1);

  return (
    <div className="date-picker">
      <h2 className={`section-title ${isReworkedScheduler ? "universal-medium" : "universal-xsmall"}`}>
        {isReworkedScheduler ? "Pickup Date" : " Pickup details"}
      </h2>
      <h3 className={`section-sub-title ${isReworkedScheduler ? "universal-xsmall" : "universal-small"}`}>
        {isReworkedScheduler
          ? `${
              pickupDateOptions.length > 1 ? "Select One (Note: " : ""
            }Your courier will arrive ${formatTimeCondensedHours(pickupWindowStart)}–${formatTimeCondensedHours(
              pickupWindowEnd,
              true
            )}${pickupDateOptions.length > 1 ? ")" : ""}`
          : `${formatDateMonthDay(preferredPickupDate)}, ${formatTimeCondensedHours(
              pickupWindowStart
            )}-${formatTimeCondensedHours(pickupWindowEnd, true)}`}
      </h3>
      {isMultiDatePicker && (
        <div className="dates">
          {pickupDateOptions
            .sort((a, b) => new Date(a.pickupDate) - new Date(b.pickupDate))
            .map(date => (
              <HomePickupDateCard
                isReworkedScheduler={isReworkedScheduler}
                isSelected={date.pickupDate === preferredPickupDate}
                pickupDate={date.pickupDate}
                key={date.pickupDate}
                onClick={e => handleDateClick(e, date)}
              />
            ))}
        </div>
      )}
    </div>
  );
};

HomePickupDatePicker.propTypes = propTypes;

export default HomePickupDatePicker;
