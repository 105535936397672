import React from "react";
import PropTypes from "prop-types";
import SadFaceIcon from "images/referrals/sad_face_icon.svg";
import AtomSecondaryButton from "components/source/atoms/atom-secondary-button";

const propTypes = {
  errorHandler: PropTypes.func,
};
const HomePickupSchedulerError = ({ errorHandler }) => (
  <div className="Home-Pickup-Error">
    <SadFaceIcon className="icon" />
    <h4 className="header">We took a wrong turn</h4>
    <p className="copy">
      Looks like wires got crossed while scheduling your pickup. Drop off your returns instead, and try scheduling again
      next time.
    </p>
    <AtomSecondaryButton onClick={errorHandler} buttonText="GOT IT" />
  </div>
);

HomePickupSchedulerError.propTypes = propTypes;

export default HomePickupSchedulerError;
