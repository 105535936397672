import React from "react";
// This component was designed to allow an alternative image for a product, from
// a sprite, to be rendered as opposed to our "standard" product image. If no
// such image is provided, then it defaults to rendering a standard product
// image via the `ProductImage` component.
import PropTypes from "prop-types";
import ProductImage from "components/source/shared/product-image";
import {
  getProductImageAltText,
  ProductImageSize,
  selectMainImageGroup,
  selectImageWithModel,
} from "helpers/product-image-helpers";
import { productPropType } from "../../propTypes";

export default function ProductImageWrapper(props) {
  const { product, productImageClassName, size, wrapperClassName, showImageWithModel = false } = props;
  const productImages = product?.images ?? {};

  // Refactor this peice once experiment RSV_CHANGE_DEFAULT_IMAGE_IN_BAG is over
  const imageGroup = showImageWithModel ? selectImageWithModel(productImages) : selectMainImageGroup(productImages);

  return (
    <div className={wrapperClassName} data-test-id={"product-image-wrapper"}>
      <ProductImage
        altText={getProductImageAltText(product?.displayName, product?.designer?.displayName)}
        className={productImageClassName}
        imageSize={size || ProductImageSize.x70}
        imageGroup={imageGroup}
      />
    </div>
  );
}

ProductImageWrapper.propTypes = {
  product: productPropType,
  productImageClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  size: PropTypes.instanceOf(ProductImageSize),
  showImageWithModel: PropTypes.bool,
};
