import React from "react";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import SnackBar from "./SnackBar";
import SnackBarActions from "actions/snack-bar-actions";
import SnackBarHelpers from "helpers/snack-bar-helpers";
import classNames from "classnames";

class SnackBarContainerComponent extends React.Component {
  static propTypes = SnackBarHelpers.propTypes;
  static defaultProps = SnackBarHelpers.defaultSnackBarConfig;

  // CLASS PROPERTIES
  // NOTE timeoutId is a class property instead of a state property to prevent re-rendering on timeoutId updates
  timeoutId = null;

  // CLASS METHODS
  /**
   * Clear the timeout if it exists
   */
  clearSnackBarTimeout = () => {
    if (!this.timeoutId) {
      return;
    }
    clearTimeout(this.timeoutId);
    this.timeoutId = null;
  };

  // We're calling the prop of the same name within here for testability purposes
  // Clears timeout as a precaution
  clearSnackBar = () => {
    const { dismissSnackBar } = this.props;
    this.clearSnackBarTimeout();
    dismissSnackBar();
  };

  /**
   * Set the timeout if the snack bar exists, it's not visible, and has a valid duration
   * Sets the timeout ID to the class property to preven re-render
   * Clears any pre-existing timeouts
   */
  scheduleSnackBarDismissal = () => {
    const { snackBar } = this.props;

    this.clearSnackBarTimeout();

    if (!snackBar || !snackBar.isVisible || !this.isDurationValid(snackBar.duration)) {
      return;
    }

    // Set a timeout function and save the id within the component
    this.timeoutId = setTimeout(() => {
      this.clearSnackBar();
    }, snackBar.duration);
  };

  /**
   * Checks if a given duration is greater than zero and finite
   * @param {Number} duration
   * @returns {Bool}
   * @throws Will throw if duration is less than zero or infinite
   */
  isDurationValid = duration => {
    const durationValue = Number(duration);

    if (Number.isNaN(durationValue)) {
      throw new Error("Duration must be a finite number greater than zero");
    }
    if (durationValue < 0) {
      throw new Error("Duration cannot be less than zero");
    }
    if (!Number.isFinite(durationValue)) {
      throw new Error("Duration must be a finite number");
    }

    return 0 < durationValue;
  };

  // LIFECYCLE METHODS
  /**
   * Schedules the dismissal of the snack bar if there is a snack bar with a valid duration
   */
  componentDidMount = () => this.scheduleSnackBarDismissal();

  componentDidUpdate = prevProps => {
    const { snackBar } = this.props;

    // Compare objects using deep equal because the keys between props may be the same despite coming from different redux actions
    // i.e. a user swapping multiple items using the swap button will trigger multiple swap snack bars
    if (prevProps.snackBar !== snackBar) {
      this.scheduleSnackBarDismissal();
    }
  };

  componentWillUnmount = () => this.clearSnackBarTimeout();

  render = () => {
    const { snackBar, dismissSnackBar, extraClassName, bagContext } = this.props;

    if (!snackBar || (snackBar.bagContext || false) !== (bagContext || false)) {
      return null;
    }

    const shouldTransition = snackBar.isVisible || false;
    const snackBarProps = { ...snackBar, dismissSnackBar };
    const snackBarContainerClass = classNames("snack-bar-container", {
      [extraClassName]: extraClassName,
      ["snack-bar-bag-context"]: bagContext,
    });

    return (
      <CSSTransition appear unmountOnExit in={shouldTransition} classNames="snack-bar-transition" timeout={500}>
        <div className={snackBarContainerClass}>
          <SnackBar // eslint-disable-next-line react/jsx-props-no-spreading
            {...snackBarProps}
          />
        </div>
      </CSSTransition>
    );
  };
}

const mapStateToProps = store => ({
  snackBar: store.snackBar,
});

const mapDispatchToProps = dispatch => ({
  dismissSnackBar: () => dispatch(SnackBarActions.dismissSnackBar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarContainerComponent);
